import React, { useState } from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { generatePath } from "react-router-dom";

import { getPublicImage } from "../../utils/helpers";

import SvgIconStyle from "../minimal/SvgIconStyle";
import ButtonLink from "../button/ButtonLink";
import { PROVIDER_CLINIC_PROFILE_ROUTE } from "../../utils/routes";

export default function TSMarker({ clinic, disableInfo = false, idx, onOpen }) {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const toggleInfo = () => {
        setIsOpen(!isOpen);
        onOpen({ closeFn: () => setIsOpen(false) });
    };

    const LocationInfo = () => {
        return (
            <Stack sx={{ pl: 2, pr: 1, mt: 2, minWidth: "300px" }} key={idx}>
                {clinic && clinic.title && (
                    <Typography variant="h5" sx={{ fontWeight: 700 }}>
                        {clinic.title}
                    </Typography>
                )}
                <Divider />
                {clinic && clinic.address1 && (
                    <Stack direction="row" spacing={1} alignItems="start" sx={{ my: 0.5 }}>
                        <SvgIconStyle
                            src={getPublicImage("ic_location.svg")}
                            sx={{ color: theme.palette.orange.main, mt: 0.5 }}
                        />
                        <Stack>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                                {clinic.address1}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                                {clinic.address2}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                                {clinic.city}, {clinic.state}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                                {clinic.zip}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
                <Box sx={{ alignItems: "start", ml: 4 }}>
                    <ButtonLink
                        href={`${generatePath(PROVIDER_CLINIC_PROFILE_ROUTE, { clinicId: clinic.id })}/${clinic.slug}`}
                        rel="canonical"
                        title={`${clinic.title}'s profile`}
                    >
                        View {clinic.title}'s profile
                    </ButtonLink>
                </Box>
            </Stack>
        );
    };

    return (
        <Marker position={{ lat: Number(clinic.latitude), lng: Number(clinic.longitude) }} onClick={toggleInfo}>
            {isOpen && !disableInfo && (
                <InfoWindow onCloseClick={toggleInfo}>
                    <LocationInfo />
                </InfoWindow>
            )}
        </Marker>
    );
}
