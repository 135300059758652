import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { asyncDispatchMiddleware } from "./middleware";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import reduxWebsocket from "@giantmachines/redux-websocket";
import { isDevelopment } from "../utils/helpers";

const loggerMiddleware = createLogger({
    collapsed: true,
});

export const history = createBrowserHistory();
const reduxWebsocketMiddleware = reduxWebsocket({
    reconnectOnClose: true,
    deserializer: JSON.parse,
});

const middlewares = [routerMiddleware(history), thunkMiddleware, asyncDispatchMiddleware, reduxWebsocketMiddleware];

if (isDevelopment() && process.env.REACT_APP_REDUX_LOGGER_ENABLE === "true") {
    middlewares.push(loggerMiddleware);
}

export default function configureStore(preloadedState) {
    const store = createStore(createRootReducer(history), preloadedState, compose(applyMiddleware(...middlewares)));

    return store;
}
