import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, MenuItem, useTheme } from "@mui/material";
import { MenuList, Popper } from "@material-ui/core";
import ButtonLink from "../../../components/button/ButtonLink";
import SvgIconStyle from "../../../components/minimal/SvgIconStyle";
import { getPublicImage } from "../../../utils/helpers";

function MFRHeaderDropDownMenu({ label, options }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { customShadows, palette } = useTheme();

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    const handleOpen = (event) => {
        if (!open) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
            <ButtonLink
                compact
                sx={{
                    color: palette.slate.main,
                    "&:hover": {
                        backgroundColor: palette.common.white,
                    },
                }}
                onClick={handleToggle}
                aria-describedby={id}
                aria-expanded={open}
                aria-haspopup="true"
            >
                {label}
                <SvgIconStyle
                    src={getPublicImage("ic_chevron_down.svg")}
                    sx={{
                        transform: open ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.2s",
                    }}
                />
            </ButtonLink>
            <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 100 }} placement="bottom-start">
                <Box
                    sx={{
                        width: 200,
                        bgcolor: "background.paper",
                        borderRadius: "12px",
                        boxShadow: customShadows.dropdown,
                        overflow: "hidden",
                        mt: 1,
                    }}
                >
                    <MenuList autoFocus={open} style={{ padding: 0, outline: "none" }}>
                        <div>
                            {options.map((item, index) => (
                                <MenuItem key={index}>
                                    <ButtonLink
                                        href={item.href}
                                        onClick={handleToggle}
                                        sx={{
                                            color: palette.slate.main,
                                            "&:hover": {
                                                color: palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                    >
                                        {item.label}
                                    </ButtonLink>
                                </MenuItem>
                            ))}
                        </div>
                    </MenuList>
                </Box>
            </Popper>
        </div>
    );
}

MFRHeaderDropDownMenu.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default MFRHeaderDropDownMenu; 